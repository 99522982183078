<template>
  <div>
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4">
        <v-form
          @submit.prevent
          class="pa-6"
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row>
            <company-select
              @chosen="getCompanyBillingSettings"
              v-model="companySelected"
            ></company-select>
          </v-row>

          <v-row v-if="companySelected && companyBillingSettings">
            <v-col cols="12">
              <h1 class="title">Settings For {{ companySelected.name }}</h1>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                class="mt-0"
                v-model="companyBillingSettings.monthly_billing"
                label="Monthly Invoicing"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="companyBillingSettings.invoice_due_date_days"
                type="number"
                label="Invoice Due Date Days from Invoicing Date"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                outlined
                dense
                v-model="companyBillingSettings.tax_type_description"
                :items="vatTypes"
                label="Tax Type Description (select from dropdown or type it on the field)"
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="companyBillingSettings.tax_type_description"
                label="Tax Type Description (Free field edit)"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="companyBillingSettings.account_manager_email"
                label="Account Manager Email"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="companyBillingSettings.cpi_reminder_date"
                    label="CPI Reminder Date"
                    prepend-inner-icon="event"
                    readonly
                    dense
                    outlined
                    v-on="on"
                    clearable
                    @click:clear="companyBillingSettings.cpi_reminder_date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="companyBillingSettings.cpi_reminder_date"
                  @input="dateMenu = false"
                  :min="new Date().toISOString().substr(0, 10)"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12">
              <v-divider />
              <b>Minimum Charge</b>
            </v-col>

            <v-col cols="6">
              <v-text-field
                prefix="£"
                v-model="companyBillingSettings.minimum_charge"
                label="Minimum Charge (leave empty or set as 0 if not applicable)"
                hide-details
                type="number"
                outlined
                dense
                @change="validateForm()"
              ></v-text-field>
            </v-col>
            <v-col cols="6" v-if="companyBillingSettings.minimum_charge">
              <v-select
                v-model="companyBillingSettings.minimum_charge_code"
                :items="chargeCodes"
                item-text="name"
                item-value="code"
                label="Charge Code for Minimum Charge"
                outlined
                dense
                :rules="
                  companyBillingSettings.minimum_charge
                    ? [(v) => !!v || 'Charge code for minimum charge required']
                    : []
                "
              ></v-select>
            </v-col>
            <v-col cols="3" v-if="companyBillingSettings.minimum_charge">
              <v-checkbox
                v-model="companyBillingSettings.minimum_charge_all_charge_codes"
                hide-details
                @change="validateForm()"
                label="Applies to All Charge Codes"
              ></v-checkbox>
            </v-col>

            <v-col
              cols="9"
              v-if="
                companyBillingSettings.minimum_charge &&
                !companyBillingSettings.minimum_charge_all_charge_codes
              "
            >
              <v-select
                v-model="selectedChargeCodes"
                :items="chargeCodes"
                item-text="name"
                item-value="code"
                label="Select Charge Codes to Which Calculate Minimum Charge"
                outlined
                dense
                multiple
                :rules="
                  companyBillingSettings.minimum_charge_all_charge_codes
                    ? []
                    : [
                        (v) =>
                          v.length > 0 ||
                          'At least one minimum charge code required',
                      ]
                "
              ></v-select>
            </v-col>
            <v-col cols="12" justify="center" align="center">
              <v-btn
                color="primary"
                @click="saveCompanyBillingSettings()"
                :loading="loading"
                :disabled="!validForm"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import BillingSnap from "@/services/BillingSnap.js";
import AdhocCharges from "@/services/AdhocCharges.js";
import CompanySelect from "@/components/forms/CompanySelect";
export default {
  components: { CompanySelect },
  data() {
    return {
      validForm: true,
      // Title
      title: "Company Billing Settings",
      // Snackbar
      snackbar: false,
      text: "",
      loading: false,
      companySelected: null,

      companyBillingSettings: null,
      vatTypes: [],

      chargeCodes: [],
      selectedChargeCodes: [],
      dateMenu: false,
    };
  },

  created() {
    this.getAllVatTypesCreated();
    this.getChargeCodes();
  },

  methods: {
    validateForm() {
      this.$refs.form.validate();
    },
    async getChargeCodes() {
      // Use the BillingSnap to call the getBillingData() method
      AdhocCharges.getChargeCodes("").then((chargeCodes) => {
        this.chargeCodes = chargeCodes;
      });
    },
    getAllVatTypesCreated() {
      this.vatTypes = [];
      this.loading = true;
      BillingSnap.getCompanyBillingSettings()
        .then((response) => {
          this.loading = false;
          let vatTypes = [];
          if (response && response.length) {
            for (let row of response) {
              let rowVatType = row.tax_type_description;
              if (!vatTypes.includes()) {
                vatTypes.push(rowVatType);
              }
            }
            this.vatTypes = vatTypes;
          }
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    resetForm() {
      this.selectedChargeCodes = [];
      this.companyBillingSettings = null;
    },
    getCompanyBillingSettings(company) {
      if (!company || !company.id) return false;
      this.companySelected = company;
      this.loading = true;
      this.resetForm();
      BillingSnap.getCompanyBillingSettings(company.id)
        .then((response) => {
          this.loading = false;
          if (!response) {
            let defaultSettings = {
              monthly_billing: false,
              tax_type_description: "20% (VAT on income)",
              invoice_due_date_days: 14,
            };
            this.companyBillingSettings = defaultSettings;
          } else {
            this.companyBillingSettings = response;
            if (this.companyBillingSettings.minimum_charge_codes) {
              this.selectedChargeCodes =
                this.companyBillingSettings.minimum_charge_codes.map(
                  (el) => el.charge_code
                );
            }
          }
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },

    saveCompanyBillingSettings() {
      if (!this.$refs.form.validate()) {
        this.snackbar = true;
        this.text = "Not ready to be saved, check the form for required fields";
        return;
      }

      let companyId = this.companySelected.id;
      let reqBody = this.companyBillingSettings;
      reqBody.company_id = companyId;

      // delete unwanted keys from object
      delete reqBody.created_at;
      delete reqBody.updated_at;
      delete reqBody.company;

      if (reqBody.minimum_charge_all_charge_codes) {
        reqBody.minimum_charge_codes = [];
      } else {
        reqBody.minimum_charge_codes = this.selectedChargeCodes;
      }

      this.loading = true;
      BillingSnap.saveCompanyBillingSettings(reqBody)
        .then((response) => {
          this.loading = false;
          if (response) {
            this.snackbar = true;
            this.text = "Company Settings Saved";
            this.getCompanyBillingSettings(this.companySelected);
          }
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
  },
};
</script>
<style>
.v-card .v-card__title {
  font-size: 1rem;
}
.wrap-text {
  -webkit-line-clamp: unset !important;
  white-space: normal;
}
</style>
